<template>
  <div class="parent-mainten">
    <div class="maintenance-history">
      <div class="d-flex mb-1 align-items-center justify-content-between">
        <h4 class="card-title">
          {{ $t("Maintenance.Reminders") }}
        </h4>
        <div class="filter-btn-outer-new">
          <b-button
            v-b-tooltip.hover.v-primary
            :title="$t('Back')"
            variant="outline-primary"
            class="mobile-hidden"
            @click="MaintenanceHome"
          >
            <span class="mobile-hidden">{{ $t("Back") }}</span>
          </b-button>
        </div>
      </div>
      <b-card>
        <div class="d-flex mb-1 align-items-center justify-content-between">
          <b-media class="align-item-center unit-list-map-icon-parent-1 pt-0">
            <template #aside>
              <span class="unit-list-map-icon-parent">
                <b-avatar
                  circle
                  variant="primary"
                  class="maintenance-unit-icon"
                  height="10"
                  v-if="unit && unit.icon"
                  :src="unit.icon"
                />
              </span>
            </template>
            <h6 class="mb-0">{{ unit && unit.name }}</h6>
            <small class="text-muted"
              ><b>{{ unitService && unitService.name }}</b></small
            >
          </b-media>
          <div class="d-flex">
            <b-button
              variant="primary"
              size="sm"
              class="history-back-btn desktop-hidden mr-1"
              @click="showFiltermenu = !showFiltermenu"
            >
              <feather-icon icon="FilterIcon" size="15" />
            </b-button>

            <b-button
              variant="outline-primary"
              size="sm"
              class="back-button desktop-hidden v2-back"
              @click="MaintenanceHome"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
            </b-button>
          </div>
          <div
            class="d-flex flex-wrap filter-card pb-0 MaintenanceHistory"
            v-if="showFiltermenu"
          >
            <div class="date-width" v-if="showFiltermenu">
              <span class="mr-1">{{ $t("MaintenanceHistory.Search") }}</span>
              <DatePicker
                v-model="dateFilter"
                :isCancel="true"
                :options="{
                  is_time: true,
                  single_date: false,
                  ranges: true,
                  opens: 'left'
                }"
              />
            </div>
          </div>
        </div>
        <div class="table-responsive" v-if="show">
          <b-skeleton-table
            v-if="show"
            :rows="10"
            :columns="7"
            :table-props="{ bordered: true, striped: true }"
            class="device-list2"
          />
        </div>
        <b-table
          class="position-relative maintenance-table dre-table"
          responsive
          show-empty
          v-if="!show"
          sticky-header
          align-v="end"
          :items="items"
          :fields="tableColumns"
          :empty-text="$t('NoMatchingRecordsFound')"
        >
          <template #cell(services)="row">
            <ul class="p-0 mt-0 mb-0">
              <li
                class="font-500 services"
                v-for="service in row.item.services"
                :key="service.value"
              >
                {{ service.title }}
              </li>
            </ul>
          </template>
          <template #cell(actual_service_date)="row"
            >{{ DateTimeConvert(row.item.actual_service_date) }}
          </template>

          <template #cell(odometer)="row"> {{ row.item.odometer }} </template>
          <template #cell(engine)="row"> {{ row.item.engine }} </template>
          <template #cell(total_cost)="row">
            {{ row.item.total_cost }}
          </template>
          <template #cell(ACTIONS)="row">
            <div class="text-left min-80">
              <span>
                <b-link
                  :to="{
                    name: 'maintenance-reminder-history-details',
                    params: { id: row.item.id }
                    //  params: { id: '9c369f8f-47a1-4f7f-ac14-6ca35f3bec03' }
                  }"
                >
                  <feather-icon
                    v-b-tooltip.hover.top="$t('tooTip.view')"
                    icon="EyeIcon"
                    class="text-primary action-icon cursor-pointe"
                    size="18"
                  />
                </b-link>
              </span>
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              &nbsp;
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-if="totalMaintenanceHistory > 0"
                v-model="currentPage"
                :total-rows="totalMaintenanceHistory"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                :no-paging-nav="false"
                @input="handlePageChange"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row></div
      ></b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BTable,
  BPagination,
  BFormGroup,
  VBTooltip,
  BLink,
  BMedia,
  BAvatar,
  BSkeletonTable
} from "bootstrap-vue";
import MaintenanceService from "@/libs/api/maintenance-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Loader from "@/layouts/components/Loader.vue";
import constants from "@/utils/constants";
import DatePicker from "./DatePicker.vue";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BTable,
    BLink,
    BSkeletonTable,
    BPagination,
    BFormGroup,
    VBTooltip,
    Loader,
    BMedia,
    DatePicker,
    BAvatar
  },
  data() {
    return {
      showFiltermenu: false,
      show: false,
      unit: {},
      unitService: {},
      dateFilter: "",
      perPage: this.$route.query.perPage ? this.$route.query.perPage : 10,
      currentPage: this.$route.query.currentPage
        ? this.$route.query.currentPage
        : 1,
      status: false,
      items: [],
      totalMaintenanceHistory: 0,
      option: ["10", "20", "30"],
      tableColumns: [
        {
          key: "services",
          tdClass: "",
          thClass: "",
          tdClass: "badge-200",
          thClass: "badge-200",
          label: this.$t("Maintenance.tableColumns.services")
        },
        {
          key: "actual_service_date",
          tdClass: "badge-200",
          thClass: "badge-200",
          label: this.$t("Maintenance.tableColumns.actualServiceDate")
        },

        {
          key: "odometer",
          tdClass: "badge-200",
          thClass: "badge-200",
          label: this.$t("Maintenance.tableColumns.odometer")
        },
        {
          key: "engine",
          tdClass: "badge-200",
          thClass: "badge-200",
          label: this.$t("Maintenance.tableColumns.engineHour")
        },
        {
          key: "total_cost",
          tdClass: "text-center ",
          thClass: "text-center ",
          label: this.$t("Maintenance.tableColumns.cost")
        },
        {
          key: "ACTIONS",
          thClass: "action-width-maintenance",
          tdClass: "action-width-maintenance",
          label: this.$t("Maintenance.tableColumns.actions")
        }
      ]
    };
  },
  setup() {},
  watch: {
    perPage(val) {
      if (!this.perPage) {
        this.perPage = 10;
      }
      this.updateUrl({ key: "perPage", value: this.perPage });
      this.getAllReminders();
    },
    currentPage(newPage) {
      this.getAllReminders();
    },
    dateFilter() {
      this.getAllReminders();
    }
  },
  directives: {
    "b-tooltip": VBTooltip
  },

  beforeDestroy() {},
  computed: {},
  methods: {
    updateUrl(e) {
      this.$router.push({
        query: { ...this.$route.query, [e.key]: e.value }
      });
    },
    onWindowResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 820) {
        this.showFiltermenu = true; //Mobile
      } else {
        this.showFiltermenu = false; //Desktop
      }
    },

    DateTimeConvert(date) {
      return this.formattedDateTime(date, constants.DATE_FORMAT);
    },
    MaintenanceHome() {
      this.$router.push({ name: "maintenance-reminder-list" });
    },
    handlePageChange(newPage) {
      this.updateQueryParam();
    },
    updateQueryParam() {
      const query = {
        ...this.$route.query,
        page: this.currentPage.toString()
      };

      this.$router.replace({ query }).catch(() => {});
      this.getAllReminders();
    },
    async getAllReminders() {
      try {
        this.show = true;
        const me = this;
        let filters = [];
        // console.log(this.dateFilter);
        if (this.dateFilter && this.dateFilter.includes("to")) {
          const dates = this.dateFilter.split(" to ");
          if (dates[0] && dates[1] && dates[0] !== "null") {
            filters = [
              {
                field: "time",
                start_value: dates[0],
                end_value: dates[1],
                operator: "between"
              }
            ];
          }
        } else if (
          this.dateFilter &&
          this.dateFilter.startDate &&
          this.dateFilter.endDate
        ) {
          filters = [
            {
              field: "time",
              start_value: this.dateFilter.startDate,
              end_value: this.dateFilter.endDate,
              operator: "between"
            }
          ];
        } else if (
          this.dateFilter &&
          this.dateFilter.startDate &&
          this.dateFilter.endDate
        ) {
          filters = [
            {
              field: "time",
              start_value: this.dateFilter.startDate,
              end_value: this.dateFilter.endDate,
              operator: "between"
            }
          ];
        }
        const requestData = {
          page: parseInt(this.currentPage),
          page_size: parseInt(this.perPage),
          id: this.$route.params.id,
          filters: filters
        };

        let response = await new MaintenanceService().getCompletedReminders(
          requestData
        );
        this.show = false;
        if (response && response.data) {
          this.unit = response.data.unit;
          this.unitService = response.data.service;

          this.items = response.data.list || [];

          this.totalMaintenanceHistory = response.data.pagination.total_records;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    }
  },

  mounted() {
    this.onWindowResize();
    // window.addEventListener("resize", this.onWindowResize);
    this.$nextTick(() => {
      const { page, filter, perPage } = this.$route.query;

      if (!page) {
        this.updateUrl({ key: "page", value: 1 });
      } else {
        this.currentPage = parseInt(page);
      }

      if (filter) {
        this.filter = filter;
      }

      if (perPage) {
        this.perPage = parseInt(perPage);
      }
      this.getAllReminders();
    });
  }
};
</script>
<style lang="scss">
#modal-notification___BV_modal_title_ {
  font-size: 20px;
  margin-left: auto;
}
.action-width-maintenance {
  max-width: 100px;
  width: 100px;
  min-width: 100px;
  // text-align: end !important;
  padding: 0.72rem 1rem !important;
  text-align: left !important;
}

.services {
  white-space: nowrap;
}

.try .media-body {
  display: flex;
  // justify-content: center !important;
}
.label-w-a {
  div {
    text-align: left;
  }
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss" scoped>
.history-back-btn {
  height: 22px;
}
.filter-history-outer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.label-w-a {
  max-width: 170px;
  width: 170px;
  min-width: 170px;
  text-align: left !important;
}
.btn.btn-icon {
  padding: 0px !important;
}
.disabled-maintenance-icon {
  pointer-events: none;
  opacity: 0.5;
}

.maintenance-history {
  .b-table-sticky-header {
    max-height: calc(100vh - 300px) !important;
    overflow-y: auto;
    .table {
      width: calc(100% - 18px) !important;
    }
  }
}
.setting_icon_padding {
  padding: 0px 8px 0 8px;
}
.font-500 {
  font-weight: 500;
}
.config-due {
  white-space: nowrap;
}
.font-12 {
  font-size: 12px;
}
</style>
<style lang="scss">
.b-table-sticky-header {
  @supports (position: sticky) {
    .table.b-table > thead > tr > .table-b-table-default,
    .table.b-table > tbody > tr > .table-b-table-default,
    .table.b-table > tfoot > tr > .table-b-table-default {
      top: -1px !important;
    }
  }
}
.dark-layout .b-table-sticky-header {
  @supports (position: sticky) {
    .table.b-table > thead > tr > .table-b-table-default,
    .table.b-table > tbody > tr > .table-b-table-default,
    .table.b-table > tfoot > tr > .table-b-table-default {
      color: #d0d2d6 !important;
    }
  }
}

.mr-30 {
  margin-right: 30px;
  min-width: 85px;
}
.white-space {
  white-space: nowrap;
}
.maintenance-history {
  table tr th {
    text-align: left !important;
  }
  table tr td {
    text-align: left !important;
  }
}

.date-width {
  display: flex;
  align-items: center;
  min-width: 200px;
}
.maintenance-history {
  .active.in-range.start-date.end-date {
    background-color: #357ebd;
    border-color: transparent;
    padding-left: 10px !important;
  }
}
.maintenance-unit-icon {
  img {
    object-fit: contain !important;
  }
}
.MaintenanceHistory {
  padding-bottom: 15px !important;
}
@media screen and (max-width: 500px) {
  .parent-mainten {
    .unit-list-map-icon-parent-1 {
      max-width: 60%;
      .media-body {
        max-width: 72%;
      }
    }
  }
}
</style>
